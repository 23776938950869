footer {
  text-align: center;
  margin-top: 13px;
}

@media screen and (max-width: 1280px) {
  footer {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  footer {
    margin-top: 40px;
  }
}
