@media screen and (max-width: 736px) {
  #sidebar {
    display: initial;
  }

  .card-sign-action {
    min-height: 100vh;
    padding-top: 20%;
  }
}

@media screen and (max-width: 1280px) {
  .logo img {
    padding-top: 20px;
    margin-bottom: -20px;
  }

  #sidebar {
    height: 7em;
  }
}

#sidebar {
  overflow: hidden;
}

.logo img {
  max-width: 100px;
}
