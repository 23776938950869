.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal .Modal-container {
  min-height: 50vh;
  height: 50vh;
  width: 70vh;
  background-color: white;
  border-radius: 10px;
  color: #312450;
  padding: 1em;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.25);
}

.Modal .Modal-body {
  text-align: justify;
  min-height: 38vh;
}

.Modal .Modal-footer {
  text-align: justify;
  min-height: 10vh;
}

.Modal .Modal-footer .btn {
  margin-top: -50px;
  width: 100%;
  background-color: #312450;
  color: white;
}
