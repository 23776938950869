.Toastify__toast-container {
  z-index: 10000000000;
}

.wrapper.fullscreen {
  min-height: 95vh;
}

.main-iframe {
  display: inline-block;
  z-index: 100000;
  width: 100%;
  height: 100vh;
}

@media screen and (min-width: 1281px) {
  .main-iframe {
    padding-left: 396px !important;
  }
}

@media screen and (max-width: 768px) {
  .main-iframe {
    margin-top: 112px !important;
  }

  li.hide-nav {
    display: none !important;
  }

  #sidebar nav ul li {
    margin: 0 0 0 1em;
  }
}

.hbspt-form {
  margin-top: 100px;
  background-color: white;
  padding: 20px;
}

@media screen and (min-width: 1280px) {
  .hbspt-form {
    margin-top: 55px;
    background-color: white;
    padding: 20px;
    padding-left: 450px;
  }
}

@media screen and (max-width: 1280px) {
  .wrapper.fullscreen {
    min-height: 85vh;
  }
}

@media screen and (max-width: 768px) {
  .wrapper.fullscreen {
    min-height: 85vh;
  }
}
